<template>
  <va-card :title="$t('teams.equipements.none')">
    <va-inner-loading :loading="loading">
      <div class="row justify-content-center">
        <div
          class="flex xs12"
          v-for="number of numbers"
          v-bind:key="number.cant + '-' + number.name"
        >
          <ul>
            <li>
              {{ $t(number.name) }}
              <span>
                {{ number.cant }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </va-inner-loading>
    <div class="exportExcel">
      <va-button
        v-if="numbers.length > 0"
        medium
        color="primary"
        icon="fa fa-file-excel-o"
        @click.prevent="prepareDownload()"
        >{{ $t("tables.actions.export") }}</va-button
      >
    </div>
  </va-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "dashboard-teams-noequipments",
  components: {},
  data() {
    return {
      loading: false,
      error: false,
      numbers: [],
      lang: this.$root.$i18n.locale,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  created() {
    this.getNumbersData();
  },
  methods: {
    async getNumbersData() {
      this.loading = true;
      this.error = false;
      let d = null;

      try {
        d = await this.$http.get("/teams/equipments/noequipmentnumbers");
      } catch (e) {
        this.error = true;
        return;
      }
      this.loading = false;
      this.numbers = d.data.data;
    },

    async prepareDownload() {
      const prepareRoute = "teams/noequipment/download";
      const downloadRoute = "teams/noequipment/download/";

      return this.downloadFile(
        { lang: this.lang },
        prepareRoute,
        downloadRoute
      );
    },

    async downloadFile(filters, prepareRoute, downloadRoute) {
      let fileName = "";

      const prepare = {
        type: "info",
        title: this.$t("notifications.download.prepare"),
        confirmButtonText: this.$t("notifications.download.button"),
        text: this.$t("notifications.download.wait"),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),

        preConfirm: async () => {
          let data = false;
          try {
            console.log("filters", filters);
            data = await this.$http.post(prepareRoute, filters);
          } catch (e) {
            await this.$swal.queue([error]);
            return;
          }
          fileName = data.data.data;
          this.$swal.insertQueueStep(download);
        },
      };

      const error = {
        type: "error",
        title: "Error",
        text: this.$t("notifications.download.error"),
      };

      const download = {
        type: "success",
        title: this.$t("notifications.download.downloading"),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading();
          let response = false;
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: "blob",
            });
          } catch (e) {
            await this.$swal.queue([retry]);
            return;
          }

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          this.$swal.close();
        },
      };

      const retry = {
        type: "error",
        title: "Error",
        text: this.$t("notifications.download.failed"),
        confirmButtonText: this.$t("notifications.download.retry"),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download);
        },
      };

      this.$swal.queue([prepare]);
    },
  },
};
</script>

<style scoped lang="scss">
.dashboard-teams-noequipments {
  .inner-loading {
    height: 100%;
  }
}

.exportExcel {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>
